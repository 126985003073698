/*eslint-disable */
<template>
  <div class="questionContainer" :class="propAppCategory ? '' : 'pl-3'" v-if="questionList.length">
    <!--columns-->
    <div class="iq-card row questionRow m-0 w-100 p-0">
      <!--Timer-->
      <div class="col-md-12 col-lg-12 col-sm-12 que">
        <div class="sidebarContainer m-2">
          <div class="tags is-marginless" :key="timer.sec">
            <h3 class="tag is-rounded mb-2" v-if="!propAppCategory || examDetail.has_timer">
              <span> {{examDetail.app_name}} </span>
                <span class="duration" id="timer" v-if="examDetail.app_duration > 0 && examDetail.has_timer">
                    <span class="clock_boxs"><span class="clock_box1">{{timer.min}}&nbsp;Min&nbsp;</span>
                    <span class="colon">:</span>
                    <span class="clock_box2">{{timer.sec}}&nbsp;Sec&nbsp;</span>
                </span>
              </span>
            </h3>
          </div>
        </div>
      </div><!--Timer-->

      <!--questionBox-->
      <div class="col-md-12 col-lg-12 col-sm-12 questionBox">
        <!--row-->
        <div class="row">
          <!--qusetion Container-->
          <div class="col-12 col-md-12 col-lg-5 col-xl-5">
            <span>
              <h4 class="smalltext title is-2 mt-1">Question {{ questionIndex + 1 }} of {{questionList.length}}</h4><br>
              <h2 class="title is-2 questionTag vhtmlUlLi" v-html="questionList[questionIndex].question"></h2>
            </span><br>

            <!--
            <p class="mb-1 mt-1">Difficulty: {{questionList[questionIndex].difficulty}}</p>
            <p>Skill: {{questionList[questionIndex].skill}}</p>
            -->

            <!-- images -->
            <img class="que_img" v-if="questionList[questionIndex].image" style="" :src="questionList[questionIndex].image" />
            <!-- images -->

            <!-- Zoom & Calculator -->
            <button v-if="questionList[questionIndex].question.includes('<img src')" class="btn pull-right" title="Image Zoom">
              <i @click="imgModal(questionList[questionIndex].question)" class="fa fa-search-plus primary-color font-size-30" aria-hidden="true"></i>
            </button>
            <button v-if="questionList[questionIndex].image" class="btn pull-right" title="Image Zoom">
              <i @click="imgModal(questionList[questionIndex].image)" class="fa fa-search-plus primary-color font-size-30" aria-hidden="true"></i>
            </button>
            <button class="btn pull-right" v-if="questionList[questionIndex].has_calc === 1" @click="showCalculatorModal= !showCalculatorModal" title="Desmos Calculator">
              <i class="fa-solid fa-calculator font-size-30 primary-color" aria-hidden="true"></i>
            </button>
            <!-- Zoom & Calculator -->
          </div><!--qusetion Container-->

          <div class="col-lg-1 col-xl-1">&nbsp;</div>

          <!--Answer/Options Container-->
          <div v-if="questionIndex < questionList.length && quizStarted" class="questionContainer col-12 col-md-12 col-lg-6 col-xl-6" v-bind:key="questionIndex">
            <!-- Options -->
            <div class="optionContainer" :key="update">
              <template v-if="questionList[questionIndex].is_mcq == 1">
                <div class="option" v-for="(choice, optionNo) in questionList[questionIndex].answer_choices" @click="selectOption(optionNo)" :class="{ 'is-selected': userResponses[questionList[questionIndex].qbk_id] && userResponses[questionList[questionIndex].qbk_id][optionNo] }" :key="optionNo">
                  <span class="optionNo">
                    <span class="capitalize mr-2">{{ optionNo}}</span>
                  </span>
                  <span class="vhtmlUlLi" v-html="choice"></span>
                </div>
              </template>
              <template v-else>
                <b-form-textarea v-if="questionList[questionIndex].qbk_id in userResponses" placeholder="Write answer here" class="rounded form-control" rows="4" max-rows="10" autocomplete="off" v-model="userResponses[questionList[questionIndex].qbk_id].answer" style="line-height: 1.3; z-index: 9" required />
              </template>
            </div><!-- Options -->
          </div><!--Answer/Options Container-->

          <!--quizFooter: navigation and progress-->
          <div class="questionFooter col-12 mt-2">
            <!-- prevButton -->
            <b-button class="btn mr-3" @click="prev()" :disabled="questionIndex < 1">
              Previous
            </b-button><!-- prevButton -->

            <!-- nextButton -->
            <b-button variant="primary" class="btn btn-primary next" @click="next()" :disabled="questionIndex >= questionList.length">
              {{questionList.length != questionIndex+1 ? 'Next' : 'Submit'}}
            </b-button><!-- nextButton -->
            <hr/>

            <!--progress-->
            <div class="progressContainer mt-1">
              <progress
                class="progress is-success is-small w-100 mb-1"
                :value="(questionIndex / questionList.length) * 100"
                max="100"
              >
                {{ (Object.keys(userResponses).length / questionList.length) * 100 }}%
              </progress>
            </div><!--progress-->

            <div class="row attempted_total">
              <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6"><span>Attempted: {{ getAttemptedQuestions(userResponses) }}</span></div>
              <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 float-right"><span class="pull-right">Total Questions: {{questionList.length}}</span></div>
            </div>
          </div><!--quizFooter: navigation and progress-->
        </div><!--row-->

        <!--/quizCompetedResult-->
        <div v-if="questionIndex >= questionList.length && quizStarted" v-bind:key="questionIndex" class="quizCompleted has-text-centered">
          <!-- quizCompletedIcon: Achievement Icon -->
          <span class="icon is-large has-text-success">
            <i class="fa fa-check-circle-o fa-3x"></i>
          </span>

          <!--resultTitleBlock-->
          <h2 class="title">You did an amazing job!</h2>
          <p class="subtitle">
            Total score: {{ score() }} / {{ questionList.length }}
          </p>
          <!--/resultTitleBlock-->
        </div><!--/quizCompetedResult-->
      </div><!--/questionBox-->
    </div><!--/columns-->

    <b-modal id="DemosCal" style="width:100%" v-model="showCalculatorModal">
      <template #modal-header="">
        <h4 class=" mb-1 instituteTitle_block">
          Desmos Calculator
        </h4>
      </template>
      <iframe style="height: 70vh; width: 100%;" :src="desmosCalc">
      </iframe>
      <b-button size="sm" class="pull-left" @click="OpenInNewTab()">
        Expand
      </b-button>
    </b-modal>

    <!-- Show Large Image -->
    <b-modal
      v-model="showModelImageView"
      name="Modal"
      scrollable
      size="xl"
      >
      <image-view :propImageUrl="propImageUrl"/>
    </b-modal><!-- Show Large Image -->

    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
</template>
<script>
import { socialvue } from "../../../config/pluginInit"
import { QuizBanks } from "../../../FackApi/api/quizBank.js"
import { Apps } from "../../../FackApi/api/apps"
import { QuizAnswers } from "../../../FackApi/api/quizAnswers.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import cacheLs from "../../../store/cacheLs.js"
import moment from "moment"
import Utility from "../../../Utils/utility"
import ImageView from "../QuizBank/ImageView.vue"
export default {
  name: "QuestionQuiz",
  components: {
    ImageView
  },
  props: {
    propAppCategory: {
      type: String,
      default: null
    },
    propAppId: {
      type: String,
      default: null
    },
    propShowQuizModal: {
      type: Boolean,
      default: false
    },
    propModalTitle: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      questionIndex: 0,
      questionList: [],
      userResponses: {},
      userResponsesBackup: {},
      quizStarted: true,
      isActive: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Quiz Response",
      update: 0,
      examDetail: {},
      timer: { min: 0, sec: 0 },
      examId: null,
      questionIds: [],
      Utility: Utility,
      showCalculatorModal: false,
      desmosCalc: "https://www.desmos.com/calculator?lang=en",
      quizSessionId: null, // same as aprs_id,
      cvbtnModalCancel: "Close",
      propImageUrl: null,
      showModelImageView: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    gidePlusData () {
      return this.$store.getters["Paygw/myPlanState"]
    }
  },
  mounted () {
    if (!this.propAppCategory) {
      socialvue.index()
    }

    this.getExamDetail()
  },
  beforeRouteLeave (to, from, next) {
    if (!this.examDetail) {
      // User is not in the middle of the quiz
      return next()
    }

    if (this.examDetail.app_finished === 1) {
      return next()
    }

    // We dont want the user to go outside the quiz. so commenting the code below
    /*
    const answer = window.confirm("You are in the middle of a quiz. Do you really want to quit ?")
    if (answer) {
      next()
    }
    else {
      next(false)
    }
    */
  },
  methods: {
    /**
     * getAttemptedQuestions
     */
    getAttemptedQuestions (userResponses) {
      return this.questionList.length - (this.questionList.length - (Object.keys(userResponses).length - Object.keys(userResponses).filter(notAnsw => userResponses[notAnsw]["z"] == true).length))
    },
    /**
     * closeModalBox
     */
    async closeModalBox () {
      this.$emit("emitcloseInfoModalBox")
    },
    /**
     * startTimer
     */
    startTimer () {
      this.timer.sec = this.checkSecond((this.timer.sec - 1))
      if (this.timer.min < 0 && this.timer.sec < 60) {
        // Time Out now submit paper
        this.examDetail.app_finished = 1
        this.submitAnswer()
        return
      }

      if (this.$route.meta.name !== "question_quiz" && this.$route.meta.name !== "gpath_home") {
        cacheLs.remove_user_quiz_response(this.examId)
        cacheLs.remove_questions_list_ids(this.examId)
        return
      }

      let params = {
        userResponses: this.userResponses,
        time: this.timer,
        endTimeTs: this.examDetail.endTimeTs
      }
      cacheLs.set_user_quiz_response(this.examId, JSON.stringify(params))

      if (parseInt(this.timer.sec) === 59) { this.timer.min = this.timer.min - 1 }

      setTimeout(this.startTimer, 1000)
    },
    /**
     * checkSecond
     */
    checkSecond (sec) {
      if (sec < 10 && sec >= 0) { sec = "0" + sec } // add zero in front of numbers < 10
      if (sec < 0) { sec = "59" }
      return sec
    },
    /**
    * getExamDetail
    */
    async getExamDetail () {
      this.Utility.showLoader = true
      try {
        this.examId = this.$route.params.examId
        if (!this.examId) { this.examId = this.propAppId }

        let examResp = null
        if (this.propAppCategory) {
          let payload = {
            match_with: "app_category",
            app_category: this.propAppCategory
          }
          examResp = await Apps.appViewCustom(this, payload)
        }
        else {
          examResp = await Apps.appView(this, this.examId)
        }

        if (examResp.resp_data.data.app_is_gideplus && !this.gidePlusData && !this.gidePlusData.subs_id) {
          // App is paid and user is gide+ so allow else redirect outside
          this.$router.back()
          return false
        }

        if (!examResp.resp_status) {
          return ApiResponse.responseMessageDisplay(this, examResp)
        }

        this.examDetail = examResp.resp_data.data
        this.examId = this.examDetail.app_id

        if (this.examDetail.app_duration > 59) {
          this.examDetail.endTimeTs = moment().add(this.examDetail.app_duration, "seconds").utc().valueOf()

          const exanResponse = cacheLs.get_user_quiz_response(this.examId)
          this.timer.min = parseInt(parseInt(this.examDetail.app_duration) / 60)

          if (exanResponse && exanResponse.length) {
            let params = JSON.parse(exanResponse)

            if (params.endTimeTs > moment().utc().valueOf()) {
              this.userResponses = params.userResponses
              this.examDetail.endTimeTs = params.endTimeTs
              this.timer = params.time

              if (this.timer.min <= 0 && this.timer.sec <= 0) {
                cacheLs.remove_user_quiz_response(this.examId)
                cacheLs.remove_questions_list_ids(this.examId)
                this.$router.replace("/quiz")
                return
              }
            }
            else {
              this.examDetail.endTimeTs = moment().add(this.examDetail.app_duration, "seconds").utc().valueOf()
              this.examDetail.new_exam_start = 1
              cacheLs.remove_user_quiz_response(this.examId)
              cacheLs.remove_questions_list_ids(this.examId)
            }
          }
          else {
            this.examDetail.endTimeTs = moment().add(this.examDetail.app_duration, "seconds").utc().valueOf()
            this.examDetail.new_exam_start = 1
          }
        }
        else if (this.propAppCategory) {
          this.examDetail.new_exam_start = 1
        }

        if (this.examDetail.app_duration > 0 && this.examDetail.has_timer) {
          this.startTimer()
        }

        this.getQuestionsList() // Get the actual question here
      }
      catch (error) {
        console.error("Exception in getExamDetail()", error)
      }
      finally {
        this.Utility.showLoader = false
      }
    },
    /**
     * getQuestionsList
     */
    async getQuestionsList () {
      this.Utility.showLoader = true
      let questionListResp = null

      try {
        let payload = {
          app_id: this.examDetail.app_id
        }

        // Get questionIds from storage so user see same question event after refresh
        let questionStorageObj = await cacheLs.get_questions_list_ids(this.examId)
        if (questionStorageObj) {
          questionStorageObj = JSON.parse(questionStorageObj)
          if (questionStorageObj.questionListIds && questionStorageObj.questionListIds.length > 0) {
            this.questionList = questionStorageObj.questionListIds
          }

          payload.quizSessionId = questionStorageObj.quizSessionId
          this.quizSessionId = questionStorageObj.quizSessionId
        }
        else {
          questionListResp = await QuizBanks.quizBankList(this, payload)
          if (!questionListResp.resp_status) {
            if (questionListResp.resp_code === "ERR_QUIZ_NOT_STARTED") {
              alert("Quiz competition not started yet")
              this.$router.back()
              return
            }
            else if (questionListResp.resp_code === "ERR_QUIZ_NOT_PAID") {
              alert("Unauthorised access. Looks like you have not registeted for this quiz competition")
              this.$router.back()
              return
            }

            ApiResponse.responseMessageDisplay(this, questionListResp)
            return
          }

          this.questionList = questionListResp.resp_data.data
          this.quizSessionId = questionListResp.resp_data.quizSessionId

          cacheLs.set_questions_list_ids(this.examId, { "quizSessionId": this.quizSessionId, "questionListIds": this.questionList })

          for (let ques of this.questionList) {
            this.userResponses[ques.qbk_id] = { "z": true }
          }
        }

        this.questionIndex = this.getAttemptedQuestions(this.userResponses)
        if (this.questionList[this.questionIndex].is_mcq == 0) {
          // If Question is ESSAY TYPE
          this.userResponses[this.questionList[this.questionIndex].qbk_id] = {}
        }
      }
      catch (error) {
        console.error("Exception in getQuestionsList", error)
      }
      finally {
        this.Utility.showLoader = false
      }
    },
    /**
     * next
     */
    async next () {
      if (!this.userResponses[this.questionList[this.questionIndex].qbk_id]) {
        this.userResponses[this.questionList[this.questionIndex].qbk_id] = { "z": true }
      }

      if ((this.questionIndex + 1) === this.questionList.length) {
        // If last question
        const options = {
          title: this.examDetail.app_name,
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true
        }

        if (this.propModalTitle) {
          let message = "Are you ready to submit your assessment? Please note that once submitted, you won't be able to retake it."
          options.title = this.propModalTitle

          const value = await this.$bvModal.msgBoxConfirm(message, options)
          if (!value) {
            // user has clicked the review button
            this.questionIndex = 0
            return
          }
        }
        else {
          let message = "Are you ready to submit? Please note, once you submit you won't be able to retake it."

          const value = await this.$bvModal.msgBoxConfirm(message, options)
          if (!value) {
            // user has clicked the review button
            this.questionIndex = 0
            return
          }
        }

        this.examDetail.app_finished = 1
        this.submitAnswer()
        // console.log("Saving question all at once")
        // Clean up LS
        cacheLs.remove_user_quiz_response(this.examId)
        cacheLs.remove_questions_list_ids(this.examId)
      }
      else if (this.questionIndex < this.questionList.length) {
        // console.log("Saving question one at a time")
        this.submitAnswer()
      }
    },
    /**
     * prev
     */
    prev () {
      if (this.questionList.length > 0) {
        this.questionIndex--
      }
      if (!this.propAppCategory) {
        this.$router.replace({ query: { question: this.questionIndex + 1 } })
      }
      else {
        this.examDetail.app_finished = 0
      }
    },
    /**
     * selectOption
     */
    selectOption (index) {
      if (!this.userResponses[this.questionList[this.questionIndex].qbk_id]) {
        this.userResponses[this.questionList[this.questionIndex].qbk_id] = { "z": true }
      }

      if (!this.userResponses[this.questionList[this.questionIndex].qbk_id][index]) {
        // Check answer count
        if (this.questionList[this.questionIndex].answer_count > 1) {
          this.userResponses[this.questionList[this.questionIndex].qbk_id][index] = true
        }
        else if (this.questionList[this.questionIndex].answer_count === 1) {
          this.userResponses[this.questionList[this.questionIndex].qbk_id] = {}
          this.userResponses[this.questionList[this.questionIndex].qbk_id][index] = true
        }
        else {
          return
        }
      }
      else {
        delete this.userResponses[this.questionList[this.questionIndex].qbk_id][index]
      }

      if (Object.keys(this.userResponses[this.questionList[this.questionIndex].qbk_id]).length < 1) {
        delete this.userResponses[this.questionList[this.questionIndex].qbk_id]
      }

      this.update++
    },
    /**
     * submitAnswer
     */
    async submitAnswer () {
      if (this.userResponses &&
        Object.keys(this.userResponses).length &&
        this.userResponses[this.questionList[this.questionIndex].qbk_id]
      ) {
        // User has submitted the answer to the question asked. Add it to the backend DB
        let payload = {
          aprs_id: this.quizSessionId,
          qbk_id: this.questionList[this.questionIndex].qbk_id,
          qbk_category: this.questionList[this.questionIndex].qbk_category,
          app_id: this.examDetail.app_id,
          app_category: this.questionList[this.questionIndex].app_category,
          answer: null,
          exam_finished: this.examDetail.app_finished,
          new_exam_start: null,
          answer_category: null
        }

        if (this.questionList[this.questionIndex].is_mcq == 1) {
          payload.answer = Object.keys(this.userResponses[this.questionList[this.questionIndex].qbk_id]).join(",")
        }
        else {
          payload.answer = this.userResponses[this.questionList[this.questionIndex].qbk_id].answer
        }
        payload.answer_category = this.questionList[this.questionIndex].answer_category?.[payload.answer]

        if (this.examDetail.new_exam_start === 1) {
          payload.new_exam_start = 1
        }

        QuizAnswers.quizAnswersAdd(this, payload)
        this.examDetail.new_exam_start = null
      }

      if (parseInt(this.timer.min) < 0 && parseInt(this.timer.sec) < 60) {
        // Quiz Timed Out
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Quiz Time out"
        this.examDetail.app_finished = 1
      }

      if (this.examDetail.app_finished !== 1) {
        // Quiz in progress, jump to next question on answer submit
        this.questionIndex++

        if (this.questionList[this.questionIndex].is_mcq == 0 && !this.userResponses[this.questionList[this.questionIndex].qbk_id]) {
          this.userResponses[this.questionList[this.questionIndex].qbk_id] = { "z": true }
        }

        if (!this.propAppCategory) {
          this.$router.replace({ query: { question: this.questionIndex + 1 } })
        }
      }
      else {
        // Quiz over send the final submit
        let payload = {
          aprs_id: this.quizSessionId,
          app_id: this.examDetail.app_id,
          userResponses: this.userResponses,
          check_all_answers: 1,
          exam_finished: 1,
          new_exam_start: null
        }

        if (!Object.keys(this.userResponses).length) {
          // No question Selected
          payload.new_exam_start = 1
        }

        QuizAnswers.quizAnswersAdd(this, payload)

        // Passed the propAppCategory as "gpaths" (via hard coding just to trigger this emit
        if (this.propAppCategory || this.propAppId) {
          this.$emit("emitNextTab", { resp_status: true })
        }
        else {
          // Check if another quiz is chained. if found then open that chained quiz else the result
          if (this.examDetail.chained_app) {
            this.$router.replace(`/quiz_detail/${this.examDetail.chained_app}`)
          }
          else {
            let code = "thankyou"
            if (this.examDetail.app_name.includes("GPaths Impact Assessment Survey")) {
              code = "GPATHS_THANK_YOU"
            }
            else if (this.examDetail.app_name.includes("Survey")) {
              code = "thankyou"
            }

            if (this.examDetail.redirect_to_thankyou_page) {
              this.$router.replace(`/thankyou?code=${code}`)
            }
            else {
              this.$router.replace(`/app_result_view/${this.examId}/${this.userData.user_id}/1`) // 0: dont Show, 1: Show:: info message on top of the result page
            }
          }
        }

        // Quiz over clean up
        cacheLs.remove_user_quiz_response(this.examId)
        cacheLs.remove_questions_list_ids(this.examId)
      }
    },
    /**
     * score
     */
    score () {
      var score = 0
      for (let i = 0; i < this.userResponses.length; i++) {
        if (typeof this.questionList[i].responses[this.userResponses[i]] !== "undefined" &&
          this.questionList[i].responses[this.userResponses[i]].correct
        ) {
          score = score + 1
        }
      }

      return score
      // return this.userResponses.filter(function(val) { return val }).length;
    },
    /**
     * Open Demos Calculator In New
     */
    OpenInNewTab () {
      window.open(this.desmosCalc, "_blank")
    },
    /**
     * imgModal
     */
    imgModal (quesObj) {
      try {
        this.propImageUrl = quesObj
        this.showModelImageView = true
      }
      catch (err) {
        console.error("Exception occurred at ImageView() and Exception:", err.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  /* mocking native UI */
  cursor: default !important; /* remove text selection cursor */
  user-select: none; /* remove text selection */
  user-drag: none; /* disbale element dragging */
}
.title, .subtitle {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
}
.title {
  font-size: 18px !important;
  margin-top: 5px !important;
}
.animated {
  transition-duration: 0.3;
}
.que {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  padding-top: 10px;
}
.questionRow {
  background: white;
  border: 1px solid #f1f1f1;
}
.questionBox {
  padding-top: 0px;
  padding-bottom: 10px;
  .quizForm {
    display: block;
    white-space: normal;
    .quizFormContainer {
      height: 100%;
      margin: 15px 18px;
      padding: 10px;
      .field-label {
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  }
  .quizCompleted {
    width: 100%;
    padding: 25px;
  }
  .questionContainer {
    white-space: normal;
    height: 100%;
    width: 100%;
  .optionContainer {
      margin-top: 0px;
      flex-grow: 1;
      .option {
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 3px 0px 0px 50px;
        cursor: pointer;
        border: 1px solid #f1f1f1;
        overflow: hidden;
        font-size: 14px;
        min-height: 35PX;
        font-weight: 500;
        position: relative;
        &.is-selected {
          border-color: var(--iq-primary);
          background-color: white;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
    .questionFooter {
      width: 100%;
      align-self: flex-end;
      .pagination {
        //padding: 10px 15px;
        margin: 15px 25px;
      }
      .progressContainer {
        // margin: 15px 25px;
      }
    }
  }
}
h3.tag{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: capitalize;
  .duration{
    float: right;
    font-size:18px;
  }
}
.tags h3{
  font-size:18px;
  padding-bottom: 15px;
}
.progress{
  height: 0.5rem;
}
@media screen and (min-width: 769px) {
  .container {
    height: 100%;
    .columns {
      height: 100%;
      .column {
        height: 100%;
      }
    }
  }
  .questionBox {
    align-items: center;
    justify-content: center;
    padding: 20px;
    .questionContainer {
      display: flex;
      flex-direction: column;
    }
  }
  .que {
    // box-shadow: inset rgb(255 255 255) -20px 0px 20px 0px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .questionFooter{
    button{
      margin: auto;
      margin-bottom:5px;
    }
  }
}
.next{
  float:right;
}
::-webkit-progress-bar {
  background-color: #f1f1f1;
}
.optionNo {
  background-color: #e5252c;
  color: #fff;
  left: 0px;
  position: absolute;
  width: 40px;
  height: 33px;
  top: 0px;
}
.optionNo span {
  position: absolute;
  right: -9px;
  left: auto;
  top: 0;
  bottom: 0px;
  margin: auto;
  height: 0px;
  font-weight: 600;
  line-height: 0;
  font-size: 14px;
  text-align: center;
  width: 100%;
}
.questionTag {
  white-space: pre-wrap;
}
.que_img{
  min-width: 200px;
  height: auto;
  max-width: 400px;
  width: 100%;
  display: block;
}
.clock_boxs{
  border: 2px solid;
  border-color: #dcdcdc;
  border-radius:5px;
  padding: 5px;
  color: #e5252c;
}
.clock_box1{
  padding-left:5px;
  min-width: 35px;
  display: inline-flex;
  text-align: center;
}
.clock_box2{
  padding-left:10px;
  min-width: 35px;
  display: inline-flex;
  text-align: center;
}
.colon{
  width: 35px;
  border-left: 2px solid #dcdcdc;
  border-right: 2px solid #dcdcdc;
  padding: 0px 10px;
  text-align: center;
}
.smalltext.title{
  font-size: 14px !important;
  font-weight: 600;
}
</style>
