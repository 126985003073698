/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card class="image_modal_card">
          <template>
          <p v-if="propImageUrl.includes('<img src')" class="image_view vhtmlUlLi" v-html="propImageUrl"></p>
          <img v-else :src="propImageUrl"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ImageView",
  components: {
  },
  props: {
    propImageUrl: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  Mounted () {
    console.log("Gide.ai", this.propImageUrl)
  }
}
</script>
